<template>
    <div class="d-flex justify-center fill-height page">
        <v-container class="d-flex align-center justify-center flex-column fill-height mx-auto px-lg-7" :class="{'justify-center': pcOnly}">
            <v-card :flat="phoneOnly" class="pa-0" :class="{'transparent': phoneOnly}">
                <div class="d-flex align-stretch">
                    
                    <div class="form-wrapper">
                        <div class="d-flex justify-center flex-column py-lg-3 mb-3">
                            <!-- <logo width="140" /> -->
                            <v-card-title class="font-weight-semibold pt-0">Create New Account</v-card-title>
                            <v-card-subtitle class="mb-0 pb-0">Create a new account now and get TWO FREE trials immediately.</v-card-subtitle>
                        </div>
                        <v-card-text>
                            <register-form :loading="loading" @register="registerAction" />
                        </v-card-text>

                        <div class="medium-text footer mx-auto mb-2 text-center">
                            Already have an account ?
                            <v-btn dense text :to="{name: 'login'}" :disabled="loading" class="primary--text px-1">Sign In</v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-container>
        <slot name="footer" />
    </div>
</template>
<script>

// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import RegisterForm from '@/components/custom/register-form'

export default {
    name: 'register',
    data(){
        return {

            loading: false,
        };
    },
    components: {RegisterForm},
    computed:{
        ...GlobalComputed,
    },
    methods: {
        registerAction(data){
            this.loading = true;

            this.$store.dispatch('registerAction', data).then((resolveData)=>{
                this.loading = false;
                if (resolveData.redirect){
                    this.$router.push({name: 'confirm'});
                }else{
                    this.$router.push({name: 'home'});
                }
            }).catch(error=>{
                this.loading = false;
                if (error.response && error.response.status === 422){
                    // coming errors from backend
                    let errors = error.response.data.errors;
                    this.showMsg(errors[Object.keys(errors)[0]][0]);
                }else{
                    this.showMsg('Error occurred during creating your account');
                }
            })
        },
        
        showMsg(text, color = 'error', timeout = 4000){
            this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: true});
        },
        
        ...GlobalMethods,
        
    },
    watch: {
        fetchedUserCountry(country){
            if (country === 'EG'){
                this.$router.push({name: 'login-teachers'});
            }
        },
    },
    mounted(){
        if (this.fetchedUserCountry === 'EG'){
            this.$router.push({name: 'login-teachers'});
        }
    }
}
</script>

<style lang="scss" scoped>
    
    .form-wrapper{
        @media(min-width: 960px){
            width: 550px;
        }
    }

</style>

<style lang="scss">
.vue-tel-input-vuetify{
    .country-code{
        margin-right: 6px;
    }
}
</style>